import React from 'react';

const Top = () => {

  const [participacion, setParticipacion] = React.useState([])
  const [hallFama, setHallFama] = React.useState([])
  const [totalTickets, setTotalTickets] = React.useState([])
  let topPosition = 0;
  let hallFamaPosition = 0;
  React.useEffect(()=> {
    participantes()
  },[])

  const participantes = async () => {
   const partdata = await fetch('/api/topTickets',{ method:'POST'})
   const participaciones = await partdata.json()
   setParticipacion(participaciones.users);
   setTotalTickets(participaciones.allTickets[0]);
   setHallFama(participaciones.top3);
  };

  const changeTabTop = () => {
    document.getElementById('participantesTab').classList.add('active');
    document.getElementById('participantes').classList.remove('hidden');

    document.getElementById('hallFama').classList.add('hidden');
    document.getElementById('hallFamaTab').classList.remove('active');
  }

  const changeTabHallFama = () => {
    document.getElementById('hallFamaTab').classList.add('active');
    document.getElementById('hallFama').classList.remove('hidden');

    document.getElementById('participantes').classList.add('hidden');
    document.getElementById('participantesTab').classList.remove('active');
  }

  return (
    
  <div className="topTickets page">
    <div className='navTops heroes'>
      <div id='participantesTab' className='active' onClick={changeTabTop}>TOP</div>
      <div id='hallFamaTab' onClick={changeTabHallFama}>HALL FAMA</div>
    </div>
    
    <table id="participantes" className="heroes">
      <tr className="trtitulo">
        <td>#</td>
        <td>Nombre de Participantes</td>
        <td>Puntos Sorteo</td>
        <td>Posibilidades</td>
      </tr>
      { participacion.map(item =>(
      <tr key="item._id">
        <td>{topPosition += 1}</td>
        <td>{item.displayname}</td>
        <td>{item.ptsorteo}</td>
        <td>{((100/totalTickets.allTickets) * item.ptsorteo).toFixed(2)} %</td>
      </tr>  
      ))}
    </table>
    
    <table id="hallFama" className="heroes hidden">
      <tr className="trtitulo">
        <td>#</td>
        <td>Nombre de Participantes</td>
        <td>Puntos Sorteo</td>
      </tr>
      { hallFama.map(item =>(
      <tr key="item._id">
        <td>{hallFamaPosition += 1}</td>
        <td>{item.displayname}</td>
        <td>{item.maxPtSorteo}</td>
      </tr>  
      ))}
    </table>
  </div>
  )
}

export default Top;