import React, { useState, useRef } from 'react';
import Header from './views/header'
import sorteo from './views/sorteo'
import MenuMain from './views/MenuMain'
import Main from './views/main';
import Top from './views/top'
import Redes from './views/redes'
import User from './views/user'
import Games from './views/games'
import LinkPage from './views/LinkPage'
import UserSearch from './views/UserSearch'
import StreamTimeCounter from './views/streamTimeCounter';
import AdminLogin from './views/adminLogin';
import AdminPanel from './views/adminPanel';
import Commands from './views/commands';
import Info from './views/info';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useSearchParams} from 'react-router-dom';
import {DndContext} from '@dnd-kit/core';
import PopUp from './views/PopUp';

const App = () =>{
  const [participacion, setParticipacion] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [overInfo, setOverInfo] = React.useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const streamTimeCounter = queryParameters.get("streamTimeCounter");

  React.useEffect(()=> {
    participantes()
    window.addEventListener('scroll', () => {
      if( document.getElementById('menu')){
      if (document.documentElement.scrollTop > 100) {   document.getElementById('menu').style.display = 'none';}
    if (document.documentElement.scrollTop < 80){    document.getElementById('menu').style.display = 'flex';}
    }
    })
  },[])
  
  const participantes = async () => {
   const partdata = await fetch('/api/noticias',{ method:'POST'})
   const participaciones = await partdata.json()
   setParticipacion(participaciones)
  }

  const [isDropped, setIsDropped] = useState(false);
  const [isPosition, setPosition] = useState({
    left : 0,
    top : 0
  });
  function handleDragStart(event) {
    if (event.active.id == 'draggable') {
      document.getElementById('overImg').classList.remove('over');
    }
  }
  function handleDragEnd(event) {
    if (event.over && event.over.id) {
      if (event.over) {
        setIsDropped(true);
        setPosition({
          left: event.delta.x+ isPosition.left,
          top: event.delta.y + isPosition.top
        })
      } else {
        setIsDropped(false);
      }
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: event.over.id})
      };
      fetch('/api/overInfo', requestOptions)
      .then(response => response.json())
      .then(data =>  {
        if (data) {
          setOverInfo(data);
          if (event.over && event.over.id === data.id) {
            setIsDropped(true);
            setPosition({
              left: event.delta.x+ isPosition.left,
              top: event.delta.y + isPosition.top
            })
          } else {
            setIsDropped(false);
          }
        }
      })
    } else if (event.active.id == 'draggable') {
      setTimeout(() => {
        document.getElementById('overImg').classList.add('over');
      }, 1000);
    }
  }
  
if (streamTimeCounter == 1) {
  return(
    <>
      <Router>
        <StreamTimeCounter/>
      </Router>
    </>
  )
}
  /*
* Renderizado de cada componente.
*/
  return (
    <>
      <DndContext 
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Router>
          {isDropped ? <PopUp setIsDropped={setIsDropped} setPosition={setPosition} overInfo={overInfo}/> : ''}
          <Header left={isPosition.left} top={isPosition.top} isDropped={isDropped}/>
          <MenuMain/>
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/user/:username" element={<User />} />
            <Route path="/user" element={<UserSearch />} />
            <Route path="/top" element={<Top />} />
            <Route path="/link/" element={<LinkPage />} />
            <Route path="/games" element={<Games />} />
            <Route path="/sorteo" component={sorteo} />
            <Route path="/redes" element={<Redes />} />
            <Route path="/info" element={<Info />} />
            <Route path="/info/commands" element={<Commands />} />
            <Route path="/adminLogin" element={!user.length > 0 && !user[1] ? <AdminLogin setUser={setUser} /> : <AdminPanel user={user} />} />
            <Route component={() => (<h1>ERROR 404</h1>)} />
          </Routes>
        </Router>
      </DndContext>
    </>
  )


}
export default App;
