import React from 'react';
import CommandBox from './components/commandBox';
import twitchLogo from './images/twitchLogoSquare.png';
import discordLogo from './images/discordLogoSquare.png';
import redesImage from './images/Redes100.png';

const Commands = () => {
  const [social, setSocial] = React.useState(['All']);
  const handleSocial = async (e) => {
    let type = e.currentTarget.dataset.type;
    setSocial(type);
    let buttons = document.getElementsByClassName('btnForallia');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('active');
    }
    e.currentTarget.parentElement.classList.add('active');
  }
  return (
    <div className="topTickets commandsBox page">
      <h2 className='heroes'>Comandos</h2>
      <div className='navTops heroes unselectable'>
        <div className='active btnForallia'><div className='flex' data-type='All' onClick={handleSocial}><img src={redesImage} alt="Todas" /><span>Todas</span></div></div>
        <div className='btnForallia'><div className='flex' data-type='Twitch' onClick={handleSocial}><img src={twitchLogo} alt="Twitch" /><span>Twitch</span></div></div>
        <div className='btnForallia'><div className='flex' data-type='Discord' onClick={handleSocial}><img src={discordLogo} alt="Discord" /><span>Discord</span></div></div>
      </div>
      <CommandBox social={social}></CommandBox>
    </div>
  )
}

export default Commands