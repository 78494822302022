import React from 'react';
import { Link } from 'react-router-dom'
import commandsMenu from './images/commandsMenu.png';
const Info = () => (
    <div className="topTickets page info">
        <div className='navTops heroes unselectable'>
          <div>
            <Link id="son" to="/info/commands"><img src={commandsMenu} alt="Comandos"/><span>Comandos</span></Link>
          </div>
        </div>
    </div>
)

export default Info