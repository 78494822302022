import React from 'react';

const MiniTopComp = ({topData, title, img, pointsName}) => {
    return (
        <div>
            <h3>{title}</h3>
        {topData.map((top, index) => (
            <div>
                <div>#{index+1}</div>
                <div>{top.displayname}</div>
                <div>{top[pointsName]}{img ? <img src={img}></img> :''}</div>
            </div>
        ))}
        </div>
    );
}

export default MiniTopComp;