import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import basicRed from './images/basicred.gif';
import basicGreen from './images/basicgreen.gif';
import ticketSorteo from './images/ticketSorteo.png';
import gragea from './images/gragea.png';
import biruxPlata from './images/biruxPlata112.png';
import biruxOro from './images/biruxOro112.png';

const User = (props) => { 

    const [user, setUser] = React.useState([])

    function isSub(subStatus){
        if(!subStatus){
            return "No se ha subscrito nunca.";
        }else{
            return "Activo";
        }
    }

    let { username } = useParams();
    useEffect(()=> {
        fetch('/api/user/' + username ,{ method:'POST'})
        .then(response => response.json())
        .then(data =>  setUser(data))
    },[])
    if(user.displayname == undefined ||
        user.hp == undefined ||
        user.hp_max == undefined ||
        user.ptsorteo == undefined ||
        user.sub == undefined){
        return(
            <div className="core">
                <div className="playerName">El personaje no existe o está desactualizado.</div>
                <div className="playerName">(Escribe en el chat para crear o actualizar tu personaje).</div>
            </div>
            )
        }else if(user.faccion == undefined){
            return(
                <div className="core">
                    <div className="playerName">El personaje no está en ninguna facción.</div>
                    <div className="playerName">(Canjea la facción en Twitch).</div>
                 </div>
            )
        }else{
            let imageLink = "";
            if (user.faccion === 'heroe') {
                imageLink = basicGreen;
            }
            if (user.faccion === 'villano') {
                imageLink = basicRed;
            }
            return(

                <div className="core">
                    <div className="playerName"><span className={user.faccion+"String"}>{user.faccion}</span> {user.displayname}</div>
                    <progress id="health" value={user.hp} max="100" data-label={"HP "+user.hp+" / "+user.hp_max}></progress>
                    <div className="main">
                        <div className="character"><img src={imageLink} alt="Imagen Personaje"></img></div>
                        <div className="infoTwitch flexColumn">
                            <span className='fH'><img src={ticketSorteo}></img> Tickets Sorteo: {user.ptsorteo}</span>
                            <span className='fH'><img src={gragea}></img>Plus Ultra: {isSub(user.sub)}</span>
                            <span className='fH'><img src={biruxPlata}></img>Birux Plata: {(user.biruxPlata ? user.biruxPlata : 0)}</span>
                            <span className='fH'><img src={biruxOro}></img>Birux Oro: {(user.biruxOro ? user.biruxOro : 0)}</span>
                        </div>
                    </div>
                </div>
            
            
            )
        }
}
export default User;

