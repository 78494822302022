import React from 'react';

const SorteoComp = ({text, img}) => {
    return (
        <div className='sorteoComp heroes fullCenter'>
            <h2 className='loopText'><span><img src={img}></img>{text}<img src={img}></img></span></h2>
        </div>
    )
}

export default SorteoComp;