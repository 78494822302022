import React from 'react';
import { Link } from 'react-router-dom'
import redesImage from './images/Redes100.png';
import gamesImage from './images/Games.png';
import infoImage from './images/infoMenu.png';

const MenuMain = () => (
    <div id="menu-main" className="noselect">
       <Link id="son" to="/"><img src="https://cdn-icons-png.flaticon.com/512/4170/4170674.png" alt="Inicio"/><span className="responsive">Inicio</span></Link>
       <Link id="son" to="/user"><img src="https://i.ibb.co/NTQ3BQL/5e2de8848bf32edc6b344e59713f4503.png" alt="top"/><span className="responsive">Explorar</span></Link>
       <Link id="son" to="/games"><img src={gamesImage} alt="Juegos"/><span className="responsive">Juegos</span></Link>
       <Link id="son" to="/top"><img src="https://i.ibb.co/DWcpgXz/top112.png" alt="top"/><span className="responsive">Top</span></Link>
       <Link id="son" to="/info"><img src={infoImage} alt="info"/><span className="responsive">Información</span></Link>
       <Link id="son" to="/redes"><img src={redesImage} alt="Redes"/><span className="responsive">Redes</span></Link>
    </div>
)

export default MenuMain