import React from 'react';
import FormComp from './components/FormComp';

const AdminPanel = () => {

  const [actualForm, setActualForm] = React.useState([]);
  const [actualPath, setActualPath] = React.useState('');

  const commandsForm = {
    name : {
      className: 'userSearch',
      placeholder: 'Nombre',
      type: 'text',  
    },
    description : {
      className: 'userSearch',
      placeholder: 'Descripcion',
      type: 'text', 
    },
    command : {
      className: 'userSearch',
      placeholder: 'Comando',
      type: 'text',
    },
    example : {
      className: 'userSearch',
      placeholder: 'Ejemplo',
      type: 'text', 
    },
    image : {
      className: 'userSearch',
      placeholder: 'Link de imagen',
      type: 'text',
    },
    social : {
      className: 'userSearch',
      placeholder: 'Red Social',
      type: 'text', 
    },
    cooldown : {
      className: 'userSearch',
      placeholder: 'Cooldown',
      type: 'number', 
    },
    price : {
      className: 'userSearch',
      placeholder: 'Precio',
      type: 'number', 
    },
  };

  const overInfoForm = {
    id : {
      className: 'userSearch',
      placeholder: 'Id del elemento',
      type: 'text',  
    },
    title : {
      className: 'userSearch',
      placeholder: 'Titulo del PopUp',
      type: 'text', 
    },
    description : {
      className: 'userSearch',
      placeholder: 'Descripcion',
      type: 'text',
    },
    youtubeVideo : {
      className: 'userSearch',
      placeholder: 'Enlace del video de youtube',
      type: 'text', 
    },
    instagramVideo : {
      className: 'userSearch',
      placeholder: 'Enlace del video de instagram',
      type: 'text',
    }
  };

  return (
    <div className='page'>
      <div className='topTickets'>
        <h1>Admin Panel</h1>
        <div className='navTops heroes unselectable'>
          <div onClick={() => {
            setActualForm(Object.entries(commandsForm));
            setActualPath('commands');
          }}>Comandos</div>
          <div onClick={() => {
            setActualForm(Object.entries(overInfoForm));
            setActualPath('overInfo');
          }}>overInfo</div>
        </div>
        <FormComp formFields={actualForm} apiPath={actualPath}></FormComp>
      </div>
    </div>
  )
}

export default AdminPanel;